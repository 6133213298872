<template>

  <div>
    <!-- eslint-disable max-len -->
    <div id="privacy-policy-2020">
      <h1>Sketch Art & Framing Privacy Policy</h1>
      <p>Posted: 21 Dec 2020</p>
      <p>Effective: 21 Dec 2020</p>

      <p>
        Thank you for using Sketch Art & Framing! Here we describe how we collect, use and handle your personal data
        when you use our websites, software and services (“<strong>Services</strong>”).
      </p>

      <h2>What &amp; Why</h2>

      <p>
        We collect and use the following information to provide, improve, protect and promote our Services.
      </p>

      <p>
        <em>Account information.</em> We collect, and associate with your account, the information you provide to us
        when you do things such as sign up for your account, upgrade to a paid plan and set up two-factor authentication
        (such as your name, email address, phone number, payment info and physical address).
      </p>

      <p>
        <em>Usage information</em>. We collect information relating to how you use the Services, including actions you
        take in your account (such as sharing, editing, viewing, creating and moving files or folders). We use this
        information to provide, improve and promote our Services, and protect Sketch Art & Framing users.
      </p>

      <p>
        <em>Device information</em>. We also collect information from and about the devices you use to access the
        Services. This includes things like IP addresses, the type of browser and device you use, the web page you
        visited before coming to our sites, and identifiers associated with your devices. Your devices (depending on
        their settings) may also transmit location information to the Services. For example, we use device information
        to detect abuse and identify and troubleshoot bugs.
      </p>

      <p>
        <em>Cookies and other technologies</em>. We use technologies such as cookies
        and pixel tags to provide, improve, protect and promote our Services. For example, cookies help us with
        things like remembering your username for your next visit, understanding how you are interacting with our
        Services, and improving them based on that information. You can set your browser to not accept cookies, but this
        may limit your ability to use the Services. We may also use
        third-party service providers that set cookies and similar technologies to promote Sketch Art & Framing
        services.
      </p>

      <p>
        <em>Bases for processing your data.</em> We collect and use the personal data described above in order to
        provide you with the Services in a reliable and secure manner. We also collect and use personal data for our
        legitimate business needs. To the extent that we process your personal data for other purposes, we ask for your
        consent in advance or require our partners to obtain such consent.
      </p>

      <h2>With whom</h2>

      <p>
        We may share information as discussed below, but we won’t sell it to advertisers or other third parties.
      </p>

      <p>
        <em>Others working for and with Sketch Art & Framing</em>. Sketch Art & Framing uses certain trusted third
        parties (for example, providers of customer support and IT services) for the business purposes of helping us
        provide, improve, protect and promote our Services. These third parties will access your information to perform
        tasks on our behalf, and we’ll remain responsible for their handling of your information per our instructions.
      </p>

      <p>
        <em>Law &amp; order and the public interest.</em> We may disclose your information to third parties if we
        determine that such disclosure is reasonably necessary to: (a) comply with any applicable law, regulation, legal
        process or appropriate government request; (b) protect any person from death or serious bodily harm; (c) prevent
        fraud or abuse of Sketch Art & Framing or our users; (d) protect Sketch Art & Framing’s rights, property, safety
        or interest; or (e) perform a task carried out in the public interest.
      </p>

      <p>
        Stewardship of your data is critical to us and a responsibility that we embrace. We believe that your data
        should receive the same legal protections regardless of whether it’s stored on our Services or on your home
        computer’s hard drive. We’ll abide by the following Government Request
        Principles when receiving, scrutinising and responding to government requests (including national security
        requests) for your data:
      </p>
      <ul>
        <li>Be transparent</li>
        <li>Fight blanket requests</li>
        <li>Protect all users and</li>
        <li>Provide trusted services.</li>
      </ul>

      <h2>How</h2>

      <p>
        <em>Security</em>. We have qualified, experienced IT professional staff, to keep your
        information secure and testing for vulnerabilities. We continue to work on features to keep
        your information safe in addition to things like encryption of files at rest. We
        deploy automated technologies to detect abusive behaviour and content that may harm our Services, you or other
        users.
      </p>

      <h2>Where</h2>

      <p>
        <em>Around the world</em>. To provide you with the Services, we may store, process and transmit data in the
        Canada, The United States, and locations around the world – including those outside your country. Data may also be stored
        locally on the devices you use to access the Services.
      </p>

      <h2>Your control of and access to your data</h2>

      <p>
        You have control over your personal data and how it’s collected, used and shared. For example, you can:
      </p>

      <p>
        If you would like to submit a data access request, ask for your personal data to be deleted or object to the
        processing of your personal data, please email us at
        <a target="_blank"
          href="mailto:sales@skechcalgary">sales@skechcalgary.ca</a>.
      </p>

      <p>
        <em>Sketch Art & Framing as controller or processor</em>. If you reside in North America (the United States,
        Canada and Mexico), Sketch Art & Framing, Inc. acts as your service provider.
      </p>

      <h2>Changes</h2>

      <p>
        If we’re involved in a reorganisation, merger, acquisition or sale of our assets, your data may be transferred
        as part of that deal. We’ll notify you (for example, via a message to the email address associated with your
        account) of any such deal and outline your choices in that event.
      </p>

      <p>
        We may revise this Privacy Policy from time to time, and will post the most current version on our website. If a
        revision meaningfully reduces your rights, we will notify you.
      </p>

      <h2>Contact</h2>

      <p>
        Do you have questions or concerns about Sketch Art & Framing, our Services and privacy?
        Contact us at <a target="_blank" href="mailto:sales@skechcalgary.ca">sales@sketchcalgary.ca</a>.
        If we can’t answer your question, you have the right to contact your local data protection
        supervisory authority.
      </p>
    </div>
    <!-- eslint-enable max-len -->
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class PolicyPrivacy extends Vue {
}
</script>

<style scoped>

</style>
